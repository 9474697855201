import Model from './Model'

export default class NotificationPublicSignups extends Model {
  resource () {
    return 'notification-public-signups'
  }

  parameterNames () {
    const defaultParams = super.parameterNames()
    const customParams = {
      select: 'select'
    }

    return { ...defaultParams, ...customParams }
  }
}
